<template>
  <div>
    <v-container>
      <h1 class="pb-6">My Farm</h1>
      <v-row>
        <v-col v-for="(menu, idx) in menus" :key="idx" cols="6" md="3" sm="3">
          <v-card ripple link :to="'/' + menu.url" rounded="lg" class="pa-6">
            <v-img class="ma-6" :src="getImage(menu.img)"></v-img>
            <p class="text-center">{{ menu.title }}</p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      menus: [
        {
          img: "farm_registration.svg",
          title: "Farm Registration",
          url: "my-farm/farm-registration",
        },
        {
          img: "location.svg",
          title: "Location",
        },
        {
          img: "my_documents.svg",
          title: "My Documents",
          url: "my-farm/my-documents",
        },
        {
          img: "my_geofences.svg",
          title: "My Geofences",
        },
        {
          img: "my_goals.svg",
          title: "My Goals",
        },
        {
          img: "order_supplies.svg",
          title: "Order Supplies",
        },
        {
          img: "receive_supplies.svg",
          title: "Receive Supplies",
        },
        {
          img: "invoices.svg",
          title: "Invoices",
        },
        {
          img: "my_workers.svg",
          title: "My Workers",
        },
        {
          img: "my_schedule.svg",
          title: "My Schedule",
        },
        {
          img: "my_maintenance.svg",
          title: "My Maintenance",
        },
        {
          img: "my_equipment.svg",
          title: "My Equipment",
        },
      ],
    };
  },
  methods: {
    getImage(img) {
      return "/img/my_farm/" + img;
    },
  },
};
</script>
